import React, { useContext } from 'react'
import { IoClose } from "react-icons/io5";
import ProductBag from "../ProductBag/ProductBag"
import AuthContext from '../../AuthContext'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
export default function Bag({ bagHandler , isBagOpen }) {

  const { cartItems, isLoggedIn } = useContext(AuthContext)

  const navigate = useNavigate();

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const goToSpecificRoute = () => {
    if (!isLoggedIn) {
      Swal.fire({
        title: 'وارد سایت نشده‌اید',
        text: 'برای ادامه لطفاً وارد حساب کاربری خود شوید یا یک حساب کاربری بسازید.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ورود به حساب کاربری',
        cancelButtonText: 'نادیده گرفتن'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/register');
        }
      });
    }
    else if (cartItems.length > 0) {
      navigate("/orderRegistration");
    } else {
      Swal.fire(
        'سبد خرید خالی است!',
        'لطفا ابتدا محصولات مورد نظر را انتخاب کنید',
        'error'
      );
    }
  };

  const sumPrices = (array) => {
    return array.reduce((sum, item) => sum + (item.priceWithDiscount * item.count || 0), 0)
  }

  console.log("cartItems : ", cartItems)

  // console.log( sumPrices(cartItems))

  return (
    <div dir='ltr' className=' fixed z-50 min-w-full  h-screen  bg-zinc-500/50' >


        <div className='h-screen md:w-[570px] bg-white shadow-lg flex flex-col justify-between'>
          {/* top */}
          <div className='flex justify-between items-center'>
            <span className='text-2xl font-MorabbaBold m-5' >سبد خرید</span>
            <IoClose className='size-7 m-5 cursor-pointer' onClick={() => bagHandler()} />
          </div>
          {/* mid */}
          <ul className='flex flex-col gap-2 flex-1 overflow-y-auto'>
            {
              cartItems.map((item, index) => (
                <ProductBag key={item.id} data={item} />
              ))
            }
          </ul>
          {/* buttom */}
          <div dir='rtl' className='flex p-2 md:p-5 items-center justify-between'>
            <div className='flex items-center gap-x-4'>
              <button onClick={goToSpecificRoute} className='btn px-1' > ثبت سفارش </button>
              <span className='md:text-xl text-base'>قیمت نهایی :</span>
            </div>
            <div>
              <span className='md:text-xl text-base'>{formatNumber(sumPrices(cartItems))}</span>
              <span>تومان</span>
            </div>
          </div>
        </div>

    </div>
  )
}
